import { render, staticRenderFns } from "./user-profile-photo-add.html?vue&type=template&id=d48a4292&scoped=true&external"
import script from "./user-profile-photo-add.js?vue&type=script&lang=js&external"
export * from "./user-profile-photo-add.js?vue&type=script&lang=js&external"
import style0 from "./user-profile-photo-add.scss?vue&type=style&index=0&id=d48a4292&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d48a4292",
  null
  
)

export default component.exports