/*eslint-disable */
import userProfileUpdate from '@/components/private/user-profile-photo-add';
import updateUserInfo from '@/components/private/update-user-details-form';
import {bus} from '@/main';  
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'updated-profile',
  components: {
    'update-user-info' : updateUserInfo,
    'update-profile-of-user' : userProfileUpdate,
  },
  props: [],
  data () {
    return {
      updateProfilePhotoModal:false,
      selfUserDetailData:null
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters'
    ]),
    getSelfUserInfoMethod() {
      return this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data
    },
  },
  mounted () {
    this.getSelfUserInfoMethod
    bus.$on('updateProfilePhotoModalBus', (data) => {
      this.updateProfilePhotoModal = data
    });
  },
  methods: {
    updateProfilePhotoFun(){
      document.body.classList.add('popup_open_body')
      this.updateProfilePhotoModal = true
    },
  },
  watch:{
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters
    },
  }
}