import { render, staticRenderFns } from "./updatedProfile.html?vue&type=template&id=73aa6a0a&scoped=true&external"
import script from "./updatedProfile.js?vue&type=script&lang=js&external"
export * from "./updatedProfile.js?vue&type=script&lang=js&external"
import style0 from "./updatedProfile.scss?vue&type=style&index=0&id=73aa6a0a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73aa6a0a",
  null
  
)

export default component.exports