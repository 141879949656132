/*eslint-disable */

import allCountries from './phoneCodeList.json';
import {bus} from '@/main';
import clickOutside from '@/directives/click-outside';
import toast from '@/services/toast';

export default {
  name: 'mobile-number-updated',
  components: {},
  directives: {
    clickOutside,
  },
  props: ['updatePatientInfoData'],
  data () {
    return {
      phoneNumUpdatedPopup: false,
      otpVerifyPopupShow: false,
      fullMobileCountryCode:'',
      countryList: [],
      allCountriesList: [],
      phone: {
        number: '',
        isValid: false,
        country: '',
        countryIso: '',
        countryCode:''
      },
      open:false,
      phoneNumber: {
        fullnumber:'',
        fullOTP:''
      },
    }
  },
  computed: {
  },
  mounted () {
    console.log(this.updatePatientInfoData)
    for (var i = 0; i < allCountries.length; i++) {
      let c = allCountries[i];
      this.allCountriesList.push({
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        priority: c[3] || 0,
        areaCodes: c[4] || null
      });
    }
    this.getUserCountry();
    this.phone.countryCode = this.updatePatientInfoData.mobile_country_code
    this.fullMobileCountryCode = this.phone.countryCode + ' ' + (this.updatePatientInfoData.mobile || '')
    this.phone.number = this.updatePatientInfoData.mobile
    this.phone.country = this.updatePatientInfoData.mobile_country_code
    if(this.phone.country != null && this.phone.country != ''){
      this.phone.countryIso = this.allCountriesList.filter(item => item.dialCode == this.phone.country.split('+')[1])[0].iso2;
    }

  },
  methods: {
    clickedOutside() {
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    getUserCountry() {
      var self = this;
      fetch('https://ip2c.org/s')
        .then(function(response) {
          return response.text();
        })
        .then(function(text) {
          if (!text || "1" !== text[0])
            throw new Error("unable to fetch the country");
          if (self.phone.country == '' || self.phone.country == null) {
            self.phone.countryIso = (text.substr(2, 2)).toLowerCase();
            self.phone.country = self.allCountriesList.filter(item => item.iso2 == self.phone.countryIso)[0].dialCode;
          }
          if(self.country == '') {
          }
        })
        .catch(function(error) {
          console.log('Request failed', error)
        });
    },  
    updatedProfileNumberPopupClosed() {
      bus.$emit('mobileNoUpdatePopupBus', false)
      document.body.classList.remove('popup_open_body')
    },
    chooseCountry(country) { 
      this.phone.countryIso = country.iso2;
      this.phone.countryCode = '+' + country.dialCode;
    },
    validateBeforeMobileNoSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$store.dispatch('optCreateOnMobileApiFun' , {
            mobile:this.phone.number,
            mobile_country_code:this.phone.countryCode
          })
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.user_msg);
                this.otpVerifyPopupShow = true;
                this.phoneNumber.fullOTP = ''
              }
            }).catch((err) => {
              toast.error(err.data.msg || err.data.data.non_field_errors);
            })
          return;
        }
      });
    },
    validateBeforeMobileNoVerifySubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$store.dispatch('optVerifyOnMobileApiFun' , {
            mobile:this.phone.number,
            otp: this.phoneNumber.fullOTP,
            mobile_country_code:this.phone.countryCode
          })
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.user_msg);
                bus.$emit('updatePatientInfoModalBus', false)
                this.phoneNumUpdatedPopup = false
                this.otpVerifyPopupShow = false;
                this.phoneNumber.fullOTP = ''
                this.phone.number = ''
                this.phone.countryCode = ''
              }
            }).catch((err) => {
              toast.error(err.data.msg || err.data.user_msg);
            })
          return;
        }
      });
    }
  },
  watch:{
  }
}